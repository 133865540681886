import Logo from "assets/image/LogoTJS.jpeg";
import MenuConfig from "./MenuConfig";
import RoutesConfig from "./RoutesConfig";

export default {
  LOGO: Logo, //Logo
  MENU: MenuConfig, //Konfigurasi menu pada sidebar
  ROUTES: RoutesConfig, //Konfigurasi routing
  MODUL: "Proyek",
};
