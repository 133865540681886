import Services from "services";

class ProyekApi {
  page(params) {
    return Services.get('/proyek/page', { params });
  }
  dropdown(params) {
    return Services.get('/proyek/dropdown', { params });
  }
  no_baru() {
    return Services.get('/proyek/no_baru');
  }
  store(params) {
    return Services.post("/proyek", params);
  }
  update(params) {
    return Services.put("/proyek", params);
  }
  delete(params) {
    return Services.post("/proyek/delete", params);
  }
}

export default new ProyekApi();
